<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="低代码开发平台" @moTitltFn="navPanel"></MoTitle>
    <div class="moPtContainer">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">低代码开发平台</h1>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>

      <div class="contentBgColor center">
        <div class="ptDescContent">
          <img src="@/assets/product/productPt/ptDecs.png" alt="" style="width:100%;border-radius: 10px 10px 0 0 ;">
          <div class="ptDescContentText">
            易普行低代码应用开发平台（aPaaS）采用低代码/无代码开发模式、简单易用的数据建模、灵活可视的流程编排、丰富的UI组件库、拖拽式页面构建、多终端适配，快速地为用户搭建业务系统。在适应智慧人事应用建设的同时，能够敏捷响应用户个性化系统的建设需求，在同行中具有明显的技术先进性。
          </div>
        </div>
        <h2 class="h2Desc">低代码开发平台的价值优势</h2>
        <span class="spanDesc"></span>
        <div style="width:100%;margin:20px 0 30px">
          <MoListDesc :descList="typeContent" imgWidth="50px" :showBgColor="true" ListHeight="6.125rem"></MoListDesc>
        </div>
      </div>
      <div class="contentBgColor center">
        <h2 class="h2Desc">主要功能</h2>
        <span class="spanDesc"></span>
        <img src="@/assets/product/productPt/mingn.png" alt="" style="width:100%;margin:30px 0 40px">
      </div>

      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import MoListDesc from '@/components/moListDesc'
export default {
  name: 'moPT',
  components: {
    MoTitle,
    MoListDesc
  },
  data () {
    return {
      isScroll: false,
      typeContent: [
        {
          src: require('@/assets/Cooperation/ecological/open.png'),
          title: '快速构建复杂业务应用能力',
          desc: [{ p: '零代码可视化配置，低代码个性化开发' }]
        },
        {
          src: require('@/assets/product/productPt/agile.png'),
          title: '智慧人事一体化建设核心基础能力',
          desc: [{ p: '数据中台、统一身份认证、服务大厅、移动化、国际化' }]
        },
        {
          src: require('@/assets/Cooperation/Partnership/PlatformSupport.png'),
          title: '开发运维一体化管理能力',
          desc: [{ p: '提供集开发、测试、上线、运维、运营一体化管理与服务' }]
        },
        {
          src: require('@/assets/sericeAndSupport/safeguardSystem.png'),
          title: '等保三级安全可信赖能力',
          desc: [{ p: '符合国家等级保护三级测评要求' }]
        }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/product/productPt')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/product/productPt/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moPtContainer{
  padding-top: 2.75rem;
}
.ptDescContent{
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);
  border-radius: 10px;
  margin-top: 20px;
}
.ptDescContentText{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 26px;
  text-shadow: 0px 8px 24px rgba(0,0,0,0.15);
  padding: 20px;
  box-sizing: border-box;
}
</style>
